var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import classNames from 'classnames';
import RcCheckbox from 'rc-checkbox';
import { composeRef } from 'rc-util/lib/ref';
import * as React from 'react';
import { useContext } from 'react';
import { ConfigContext } from '../config-provider';
import DisabledContext from '../config-provider/DisabledContext';
import { FormItemInputContext } from '../form/context';
import warning from '../_util/warning';
import RadioGroupContext, { RadioOptionTypeContext } from './context';
const InternalRadio = (props, ref) => {
    var _a, _b;
    const groupContext = React.useContext(RadioGroupContext);
    const radioOptionTypeContext = React.useContext(RadioOptionTypeContext);
    const { getPrefixCls, direction } = React.useContext(ConfigContext);
    const innerRef = React.useRef();
    const mergedRef = composeRef(ref, innerRef);
    const { isFormItemInput } = useContext(FormItemInputContext);
    warning(!('optionType' in props), 'Radio', '`optionType` is only support in Radio.Group.');
    const onChange = (e) => {
        var _a, _b;
        (_a = props.onChange) === null || _a === void 0 ? void 0 : _a.call(props, e);
        (_b = groupContext === null || groupContext === void 0 ? void 0 : groupContext.onChange) === null || _b === void 0 ? void 0 : _b.call(groupContext, e);
    };
    const { prefixCls: customizePrefixCls, className, children, style } = props, restProps = __rest(props, ["prefixCls", "className", "children", "style"]);
    const radioPrefixCls = getPrefixCls('radio', customizePrefixCls);
    const prefixCls = ((groupContext === null || groupContext === void 0 ? void 0 : groupContext.optionType) || radioOptionTypeContext) === 'button'
        ? `${radioPrefixCls}-button`
        : radioPrefixCls;
    const radioProps = Object.assign({}, restProps);
    // ===================== Disabled =====================
    const disabled = React.useContext(DisabledContext);
    if (groupContext) {
        radioProps.name = groupContext.name;
        radioProps.onChange = onChange;
        radioProps.checked = props.value === groupContext.value;
        radioProps.disabled = (_a = radioProps.disabled) !== null && _a !== void 0 ? _a : groupContext.disabled;
    }
    radioProps.disabled = (_b = radioProps.disabled) !== null && _b !== void 0 ? _b : disabled;
    const wrapperClassString = classNames(`${prefixCls}-wrapper`, {
        [`${prefixCls}-wrapper-checked`]: radioProps.checked,
        [`${prefixCls}-wrapper-disabled`]: radioProps.disabled,
        [`${prefixCls}-wrapper-rtl`]: direction === 'rtl',
        [`${prefixCls}-wrapper-in-form-item`]: isFormItemInput,
    }, className);
    return (
    // eslint-disable-next-line jsx-a11y/label-has-associated-control
    React.createElement("label", { className: wrapperClassString, style: style, onMouseEnter: props.onMouseEnter, onMouseLeave: props.onMouseLeave },
        React.createElement(RcCheckbox, Object.assign({}, radioProps, { type: "radio", prefixCls: prefixCls, ref: mergedRef })),
        children !== undefined ? React.createElement("span", null, children) : null));
};
const Radio = React.forwardRef(InternalRadio);
if (process.env.NODE_ENV !== 'production') {
    Radio.displayName = 'Radio';
}
export default Radio;

import DownOutlined from '@ant-design/icons/DownOutlined';
import classNames from 'classnames';
import omit from 'rc-util/lib/omit';
import * as React from 'react';
import Checkbox from '../checkbox';
import Dropdown from '../dropdown';
import { isValidElement } from '../_util/reactNode';
import DefaultListBody, { OmitProps } from './ListBody';
import Search from './search';
const defaultRender = () => null;
function isRenderResultPlainObject(result) {
    return !!(result &&
        !isValidElement(result) &&
        Object.prototype.toString.call(result) === '[object Object]');
}
function getEnabledItemKeys(items) {
    return items.filter((data) => !data.disabled).map((data) => data.key);
}
export default class TransferList extends React.PureComponent {
    constructor(props) {
        super(props);
        this.defaultListBodyRef = React.createRef();
        // =============================== Filter ===============================
        this.handleFilter = (e) => {
            const { handleFilter } = this.props;
            const { target: { value: filterValue }, } = e;
            this.setState({ filterValue });
            handleFilter(e);
        };
        this.handleClear = () => {
            const { handleClear } = this.props;
            this.setState({ filterValue: '' });
            handleClear();
        };
        this.matchFilter = (text, item) => {
            const { filterValue } = this.state;
            const { filterOption } = this.props;
            if (filterOption) {
                return filterOption(filterValue, item);
            }
            return text.includes(filterValue);
        };
        // =============================== Render ===============================
        this.renderListBody = (renderList, props) => {
            let bodyContent = renderList ? renderList(props) : null;
            const customize = !!bodyContent;
            if (!customize) {
                bodyContent = React.createElement(DefaultListBody, Object.assign({ ref: this.defaultListBodyRef }, props));
            }
            return {
                customize,
                bodyContent,
            };
        };
        this.renderItem = (item) => {
            const { render = defaultRender } = this.props;
            const renderResult = render(item);
            const isRenderResultPlain = isRenderResultPlainObject(renderResult);
            return {
                renderedText: isRenderResultPlain
                    ? renderResult.value
                    : renderResult,
                renderedEl: isRenderResultPlain ? renderResult.label : renderResult,
                item,
            };
        };
        this.getSelectAllLabel = (selectedCount, totalCount) => {
            const { itemsUnit, itemUnit, selectAllLabel } = this.props;
            if (selectAllLabel) {
                return typeof selectAllLabel === 'function'
                    ? selectAllLabel({ selectedCount, totalCount })
                    : selectAllLabel;
            }
            const unit = totalCount > 1 ? itemsUnit : itemUnit;
            return (React.createElement(React.Fragment, null,
                (selectedCount > 0 ? `${selectedCount}/` : '') + totalCount,
                " ",
                unit));
        };
        this.state = {
            filterValue: '',
        };
    }
    componentWillUnmount() {
        clearTimeout(this.triggerScrollTimer);
    }
    getCheckStatus(filteredItems) {
        const { checkedKeys } = this.props;
        if (checkedKeys.length === 0) {
            return 'none';
        }
        if (filteredItems.every((item) => checkedKeys.includes(item.key) || !!item.disabled)) {
            return 'all';
        }
        return 'part';
    }
    // ================================ Item ================================
    getFilteredItems(dataSource, filterValue) {
        const filteredItems = [];
        const filteredRenderItems = [];
        dataSource.forEach((item) => {
            const renderedItem = this.renderItem(item);
            const { renderedText } = renderedItem;
            // Filter skip
            if (filterValue && !this.matchFilter(renderedText, item)) {
                return null;
            }
            filteredItems.push(item);
            filteredRenderItems.push(renderedItem);
        });
        return { filteredItems, filteredRenderItems };
    }
    getListBody(prefixCls, searchPlaceholder, filterValue, filteredItems, notFoundContent, filteredRenderItems, checkedKeys, renderList, showSearch, disabled) {
        const search = showSearch ? (React.createElement("div", { className: `${prefixCls}-body-search-wrapper` },
            React.createElement(Search, { prefixCls: `${prefixCls}-search`, onChange: this.handleFilter, handleClear: this.handleClear, placeholder: searchPlaceholder, value: filterValue, disabled: disabled }))) : null;
        const { bodyContent, customize } = this.renderListBody(renderList, Object.assign(Object.assign({}, omit(this.props, OmitProps)), { filteredItems,
            filteredRenderItems, selectedKeys: checkedKeys }));
        const getNotFoundContent = () => {
            const contentIndex = this.props.direction === 'left' ? 0 : 1;
            return Array.isArray(notFoundContent) ? notFoundContent[contentIndex] : notFoundContent;
        };
        let bodyNode;
        // We should wrap customize list body in a classNamed div to use flex layout.
        if (customize) {
            bodyNode = React.createElement("div", { className: `${prefixCls}-body-customize-wrapper` }, bodyContent);
        }
        else {
            bodyNode = filteredItems.length ? (bodyContent) : (React.createElement("div", { className: `${prefixCls}-body-not-found` }, getNotFoundContent()));
        }
        return (React.createElement("div", { className: classNames(showSearch ? `${prefixCls}-body ${prefixCls}-body-with-search` : `${prefixCls}-body`) },
            search,
            bodyNode));
    }
    getCheckBox({ filteredItems, onItemSelectAll, disabled, prefixCls, }) {
        const checkStatus = this.getCheckStatus(filteredItems);
        const checkedAll = checkStatus === 'all';
        const checkAllCheckbox = (React.createElement(Checkbox, { disabled: disabled, checked: checkedAll, indeterminate: checkStatus === 'part', className: `${prefixCls}-checkbox`, onChange: () => {
                // Only select enabled items
                onItemSelectAll(filteredItems.filter((item) => !item.disabled).map(({ key }) => key), !checkedAll);
            } }));
        return checkAllCheckbox;
    }
    render() {
        const { filterValue } = this.state;
        const { prefixCls, dataSource = [], titleText = '', checkedKeys, disabled, footer, showSearch = false, style, searchPlaceholder, notFoundContent, selectAll, selectCurrent, selectInvert, removeAll, removeCurrent, renderList, onItemSelectAll, onItemRemove, showSelectAll = true, showRemove, pagination, direction, } = this.props;
        // Custom Layout
        const footerDom = footer && (footer.length < 2 ? footer(this.props) : footer(this.props, { direction }));
        const listCls = classNames(prefixCls, {
            [`${prefixCls}-with-pagination`]: !!pagination,
            [`${prefixCls}-with-footer`]: !!footerDom,
        });
        // ====================== Get filtered, checked item list ======================
        const { filteredItems, filteredRenderItems } = this.getFilteredItems(dataSource, filterValue);
        // ================================= List Body =================================
        const listBody = this.getListBody(prefixCls, searchPlaceholder, filterValue, filteredItems, notFoundContent, filteredRenderItems, checkedKeys, renderList, showSearch, disabled);
        // ================================ List Footer ================================
        const listFooter = footerDom ? React.createElement("div", { className: `${prefixCls}-footer` }, footerDom) : null;
        const checkAllCheckbox = !showRemove &&
            !pagination &&
            this.getCheckBox({ filteredItems, onItemSelectAll, disabled, prefixCls });
        let items;
        if (showRemove) {
            items = [
                /* Remove Current Page */
                pagination
                    ? {
                        key: 'removeCurrent',
                        onClick: () => {
                            var _a;
                            const pageKeys = getEnabledItemKeys((((_a = this.defaultListBodyRef.current) === null || _a === void 0 ? void 0 : _a.getItems()) || []).map((entity) => entity.item));
                            onItemRemove === null || onItemRemove === void 0 ? void 0 : onItemRemove(pageKeys);
                        },
                        label: removeCurrent,
                    }
                    : null,
                /* Remove All */
                {
                    key: 'removeAll',
                    onClick: () => {
                        onItemRemove === null || onItemRemove === void 0 ? void 0 : onItemRemove(getEnabledItemKeys(filteredItems));
                    },
                    label: removeAll,
                },
            ].filter((item) => item);
        }
        else {
            items = [
                {
                    key: 'selectAll',
                    onClick: () => {
                        const keys = getEnabledItemKeys(filteredItems);
                        onItemSelectAll(keys, keys.length !== checkedKeys.length);
                    },
                    label: selectAll,
                },
                pagination
                    ? {
                        key: 'selectCurrent',
                        onClick: () => {
                            var _a;
                            const pageItems = ((_a = this.defaultListBodyRef.current) === null || _a === void 0 ? void 0 : _a.getItems()) || [];
                            onItemSelectAll(getEnabledItemKeys(pageItems.map((entity) => entity.item)), true);
                        },
                        label: selectCurrent,
                    }
                    : null,
                {
                    key: 'selectInvert',
                    onClick: () => {
                        var _a;
                        const availablePageItemKeys = getEnabledItemKeys((((_a = this.defaultListBodyRef.current) === null || _a === void 0 ? void 0 : _a.getItems()) || []).map((entity) => entity.item));
                        const checkedKeySet = new Set(checkedKeys);
                        const newCheckedKeysSet = new Set(checkedKeySet);
                        availablePageItemKeys.forEach((key) => {
                            if (checkedKeySet.has(key)) {
                                newCheckedKeysSet.delete(key);
                            }
                            else {
                                newCheckedKeysSet.add(key);
                            }
                        });
                        onItemSelectAll === null || onItemSelectAll === void 0 ? void 0 : onItemSelectAll(Array.from(newCheckedKeysSet), 'replace');
                    },
                    label: selectInvert,
                },
            ];
        }
        const dropdown = (React.createElement(Dropdown, { className: `${prefixCls}-header-dropdown`, menu: { items }, disabled: disabled },
            React.createElement(DownOutlined, null)));
        // ================================== Render ===================================
        return (React.createElement("div", { className: listCls, style: style },
            React.createElement("div", { className: `${prefixCls}-header` },
                showSelectAll ? (React.createElement(React.Fragment, null,
                    checkAllCheckbox,
                    dropdown)) : null,
                React.createElement("span", { className: `${prefixCls}-header-selected` }, this.getSelectAllLabel(checkedKeys.length, filteredItems.length)),
                React.createElement("span", { className: `${prefixCls}-header-title` }, titleText)),
            listBody,
            listFooter));
    }
}

var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import * as React from 'react';
import { getRenderPropValue } from '../_util/getRenderPropValue';
import { getTransitionName } from '../_util/motion';
import { ConfigContext } from '../config-provider';
import Tooltip from '../tooltip';
const Overlay = ({ title, content, prefixCls }) => (React.createElement(React.Fragment, null,
    title && React.createElement("div", { className: `${prefixCls}-title` }, getRenderPropValue(title)),
    React.createElement("div", { className: `${prefixCls}-inner-content` }, getRenderPropValue(content))));
const Popover = React.forwardRef((props, ref) => {
    const { prefixCls: customizePrefixCls, title, content, _overlay, placement = 'top', trigger = 'hover', mouseEnterDelay = 0.1, mouseLeaveDelay = 0.1, overlayStyle = {} } = props, otherProps = __rest(props, ["prefixCls", "title", "content", "_overlay", "placement", "trigger", "mouseEnterDelay", "mouseLeaveDelay", "overlayStyle"]);
    const { getPrefixCls } = React.useContext(ConfigContext);
    const prefixCls = getPrefixCls('popover', customizePrefixCls);
    const rootPrefixCls = getPrefixCls();
    const mergedOverlay = React.useMemo(() => {
        if (_overlay) {
            return _overlay;
        }
        if (!title && !content) {
            return null;
        }
        return React.createElement(Overlay, { prefixCls: prefixCls, title: title, content: content });
    }, [_overlay, title, content, prefixCls]);
    return (React.createElement(Tooltip, Object.assign({ placement: placement, trigger: trigger, mouseEnterDelay: mouseEnterDelay, mouseLeaveDelay: mouseLeaveDelay, overlayStyle: overlayStyle }, otherProps, { prefixCls: prefixCls, ref: ref, overlay: mergedOverlay, transitionName: getTransitionName(rootPrefixCls, 'zoom-big', otherProps.transitionName) })));
});
if (process.env.NODE_ENV !== 'production') {
    Popover.displayName = 'Popover';
}
export default Popover;
